<template>
  <div class="reward-activity-settings">
    <PageTitle
      title="序號登錄模組參數設定"
      icon="chevron_left"
      style="padding-bottom: 24px"
      hideBtn
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <section class="section-block">
      <SectionTitle title="客人顯示設定" hideBtn />
      <div>
        <el-form
          v-model="formData"
          label-position="left"
          label-width="250px"
        >
          <el-form-item label="開啟顯示序號登錄活動">
            <el-switch
              v-model="formData.clientEnabled"
              data-cy="allow-order-multi-people-switch"
              class="switch-input"
              active-text="開啟"
              inactive-text="關閉"
              @change="updateSerialActivityConfig"
            />
          </el-form-item>
        </el-form>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import store from '@/store'
import { GetSerialActivityConfig, UpdateSerialActivityConfig } from '@/api/rewardActivity'

export default defineComponent({
  name: 'RewardActivitySettings',
  components: {
    PageTitle,
  },
  setup () {
    const shopId = computed(() => store.getters.shop)
    const loading = ref(false)
    const formData = reactive({
      clientEnabled: false,
    })

    const getSerialActivityConfig = async () => {
      loading.value = true
      const [res, err] = await GetSerialActivityConfig({ shopId: shopId.value })
      loading.value = false
      if (err) {
        window.$.message.error(err)
        return
      }
      formData.clientEnabled = res.clientEnabled
    }

    const updateSerialActivityConfig = async () => {
      loading.value = true
      const [, err] = await UpdateSerialActivityConfig({
        shopId: shopId.value,
        clientEnabled: formData.clientEnabled,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功!')
      getSerialActivityConfig()
    }

    onMounted(() => {
      getSerialActivityConfig()
    })

    return {
      formData,
      updateSerialActivityConfig,
    }
  },
})
</script>
